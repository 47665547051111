import axios from 'axios';
import router from '@/router';
import { useStorage } from 'vue3-storage'
import { useAuthStore } from '@/stores/auth';
import { useI18n } from 'vue-i18n';

// we define the baseURL of the api, if we connect form 127.0.0.1 or localholst we connect to api.kasefilters.fr/api otherwise we connect to the same domain /api
let baseURL = '/api';
if (window.location.hostname.startsWith('127.0.0.1') || window.location.hostname.startsWith('localhost')) {
    baseURL = 'https://api.kasefilters.fr/api';
}

const service = axios.create({
    timeout: 50000,
    baseURL: baseURL
});

service.interceptors.request.use(
    config => {
        const authStore = useAuthStore();
        const token = authStore.getToken();
        if (token) {
            config.headers['Authorization'] = token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const authHeader = response.headers['authorization'];
        if (authHeader) {
            const authStore = useAuthStore();
            authStore.setToken(authHeader);
            //storage.setStorage({key:'authHeader',data:authHeader});
            // Store the token somewhere secure,
            // you could also set it as a default header for next requests:
            service.defaults.headers.common['Authorization'] = authHeader;
        }
        return response.data;
    },
    async error => {
        if (error.response && error.response.status === 401) {
            const { locale } = useI18n();
            const authHeader = error.response.headers['authorization'];
            if (authHeader) {
                const authStore = useAuthStore();
                authStore.setToken(authHeader);
            }
            router.push({ name: locale.value + '.account.login'});
        }
        return Promise.reject(error);
    }
)

export default service;
