import request from './request'

export const subscribeNewsletter = (email,lang) => {
    return new Promise((resolve, reject) => {
        request.post(`users/newsletter`, {email,lang})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const fetchUser = () => {
    return new Promise((resolve, reject) => {
        request.get(`users/me`)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const loginUser = (email,password) => {
    return new Promise((resolve, reject) => {
        request.post(`users/login`, {email:email,password:password})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export const logoutUser = () => {
    return new Promise((resolve, reject) => {
        request.post(`users/logout`)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const createUser = (data) => {
    return new Promise((resolve, reject) => {
        request.post(`users`, data)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export const lostPassword = (email) => {
    return new Promise((resolve, reject) => {
        request.post(`users/lost-password`, {email})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const changeEmail = (email) => {
    return new Promise((resolve, reject) => {
        request.put(`users/email`, {email})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export const changePassword = (data) => {
    return new Promise((resolve, reject) => {
        request.put(`users/password`, data)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const changeAddress = (data) => {
    return new Promise((resolve, reject) => {
        request.put(`users/address`, data)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getOrders = () => {
    return new Promise((resolve, reject) => {
        request.get(`users/orders`)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getInvoice = (invoice_id) => {
    return new Promise((resolve, reject) => {
        request.get(`users/invoice/${invoice_id}`)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const saveReferral = (referral) => {
    return new Promise((resolve, reject) => {
        request.post(`users/referral`, {referral})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}