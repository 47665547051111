/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

import { createRouter, createWebHistory } from 'vue-router/auto'
//import { setupLayouts } from 'virtual:generated-layouts'
import { routes } from 'vue-router/auto-routes'
import { useStorage } from 'vue3-storage';
import Cookies from 'js-cookie';
import i18n from '@/plugins/i18n'
import cloneDeep from 'lodash.clonedeep';
import { useAuthStore } from '@/stores/auth';
import { useSettingsStore } from '@/stores/settings';
import { saveReferral } from '@/api/user';


// Define supported languages
const supportedLanguages = ['fr', 'en', 'it', 'es', 'pt','de'];

// Check if the user is a bot using the user agent
const isBot = /bot|crawl|spider|facebook|twitter|linkedin/i.test(navigator.userAgent);

// we make the route on a unique level we only keep the one with a component, and for children we had the parent path

function cleanRoutes(routes, parentPath = '') {
  return routes.reduce((acc, route) => {
    if (route.component) {
      route.path = `${parentPath}${route.path}`;
      acc.push({
        ...route, 
      });
    }
    if (route.children) {
      acc.push(...cleanRoutes(route.children, `${parentPath}${route.path}/`));
    }
    return acc;
  }, []);
}

const sroutes = cleanRoutes(routes);
// we remove the children from the routes
routes.forEach((route) => {
  delete route.children;
});
//console.log('sroutes',sroutes)

//console.log('routes',routes)
function addLangToName(route,lang){
  // if the route path start with / we remove it
  if(route.path.startsWith('/')){
    route.path = route.path.slice(1);
  }
  // we add the lang to the name
  if(route.name){
    route.name = `${lang}.${route.name}`;
  }
  // we add the lang to the meta
  route.meta = { ...route.meta, lang };
  if(route.children){
    route.children.map((child) => addLangToName(child,lang))
  }
}
function createLangRoutes(routes) {
  let res_routes = [];
  for( const lang of supportedLanguages){
    // we create a route for the lang and we add as children the routes
    const langRoutes = {
      path: `/${lang}`,
      name: lang,
      component: () => import('@/layouts/Default.vue'),
      children: cloneDeep(routes)
    }
    for(const route of langRoutes.children){
      addLangToName(route,lang);
    }
    res_routes.push(langRoutes);
    /*
    // Use cloneDeep to preserve functions/components
    const langRoutes = cloneDeep(routes);
    // we add to all the first level routes the lang prefix
    for (const route of langRoutes){
      addLangToName(route,lang);
      route.path = `/${lang}${route.path}`;
    }
    //res_routes = [... res_routes, ... langRoutes];
    */
  }
  return res_routes;
}

const routesWithLang = createLangRoutes(sroutes);
//console.log('routesWithLang',routesWithLang)

/**
 * When we have refer link, they add at the begining of the path before the lang /r/xx
 * we need to define the route with the refer link, we add to the name of the route "r.", and when route is reached we store the referal data in local storage
 */
function createReferalRoutes(routes, isChild = false){
  let res_routes = [];
  for( const route of routes){
    const croute = cloneDeep(route);
    if(!isChild){
      croute.path = `/r/:refer${croute.path}`;
    }
    if(croute.children){
      croute.children = createReferalRoutes(croute.children,true);
    }
    // we add a meta referal true to the route
    croute.meta = { ...croute.meta, referal: true };
    croute.name = `r.${route.name}`;
    croute.onEnter = (to, from, next) => {
      // we enter a route with referal linked
      console.log('onEnter',to.params.refer)
      const refer = to.params.refer;
      if(refer){
        const storage = useStorage();
        storage.setStorage({key:'referer',data:refer,expire: new Date().getTime() + 3600*24*15});
        // we save the referal in the database
        saveReferral(refer).then((response) => {
          console.log('saveReferral',response)
        }
        ).catch((error) => {
          console.log('saveReferral error',error)
        });

      } 
      next();
    }
    res_routes.push(croute);
  }
  return res_routes;

}

const routesWithReferal = createReferalRoutes(routesWithLang);

// we add the referal route to the routesWithLang
routesWithLang.push(...routesWithReferal);

//console.log('routesWithReferal',routesWithReferal)
//console.log('routesWithLang',routesWithLang)
/*
const flatRoutes = routes.flatMap((route) => route)
console.log('flatRoute',flatRoutes)
const rLayoyt = setupLayouts(routes);
console.log('rLayout',rLayoyt)
*/
//console.log(sroutes);
//const routesWithLayouts = setupLayouts(routesWithLang)
//console.log('routesWithLayouts',routesWithLayouts)
const router = createRouter({
    history: createWebHistory(),
    routes : [
        ...routesWithLang,
        {
          // Fallback route to redirect when language is missing
          path: '/:pathMatch(.*)*',
          name: 'not-found',
          redirect: (to) => {
            /**
             * we manage the redirection of old routes
             * commande_result.php?order_ref=(.*) => fr/order/$1
             * produits/(.*)-(.*).htm => fr/product/$1_$2
             * compte.htm => fr/account/my_info
             * categorie/promotons.htm => fr/category/sales
             * categorie/(.*).htm => fr
             * about.htm => fr/about-us
             * livraison.htm => fr/shipping
             * secure.htm => fr/secure
             * contact.htm => fr/contact
             * return.htm => fr/return
             * cgv.htm => fr/tcs
             * become_reseller.htm => fr/contact
             * search.php?s=(.*) => fr/search?s=$1
             * categorie/Clip-in_271.htm?r_fb => r/fb/fr/category/clipin-rear
             * r/fb_videorevolution/produits/Kase-Wolverine-Revolution-filtres-magnetiques-kit-CPL-ND8-ND64-ND1000-0652733843937.htm => r/fb_videorevolution/fr/product/Kase_Wolverine_Revolution_filtres_magnetiques_kit_CPL_ND8_ND64_ND1000_0652733843937
             */
            
            if(to.params.pathMatch==''){
              return { path: `/fr`, query: to.query };
            }
            const path = to.params.pathMatch.join('/');
            if(path){
              if(path=='' || path=='index.htm'){
                return { path: `/fr`, query: to.query };
              }
              if(path.match(/commande_result.php/) && to.query.order_ref){
                // we check if the order_ref is a number
                if(!isNaN(to.query.order_ref)){
                  return { path: `/fr/order/${to.query.order_ref}`, query: to.query };
                }
                
              }
              if(path.match(/produits\/(.*)-(.*).htm/)){
                return { path: `/fr/product/${path.replace(/produits\/(.*)-(.*).htm/,'$1_$2')}`, query: to.query };
              }
              if(path.match(/compte.htm/)){
                return { path: `/fr/account/my_info`, query: to.query };
              }
              if(path.match(/categorie\/promotions.htm/)){
                return { path: `/fr/category/sales`, query: to.query };
              }
              if(path.match(/categorie\/Clip-in_271.htm/) && to.query.r_fb){
                return { path: `/r/fb/fr/category/clipin-rear`, query: to.query };
              }
              if(path.match(/categorie\/(.*)\.htm/)){
                return { path: `/fr`, query: to.query };
              }
              if(path.match(/about.htm/)){
                return { path: `/fr/about-us`, query: to.query };
              }
              if(path.match(/livraison.htm/)){
                return { path: `/fr/shipping`, query: to.query };
              }
              if(path.match(/secure.htm/)){
                return { path: `/fr/secure`, query: to.query };
              }
              if(path.match(/contact.htm/)){
                return { path: `/fr/contact`, query: to.query };
              }
              if(path.match(/return.htm/)){
                return { path: `/fr/return`, query: to.query };
              }
              if(path.match(/cgv.htm/)){
                return { path: `/fr/tcs`, query: to.query };
              }
              if(path.match(/become_reseller.htm/)){
                return { path: `/fr/contact`, query: to.query };
              }
              if(path.match(/search.php/)){
                return { path: `/fr/category/search?s=${path.replace(/search.php\?s=(.*)/,'$1')}`, query: to.query };
              }
              

              /*
              if(path.match(/r\/(.*)\/produits\/(.*)-(.*)\.htm/)){
                console.log('target',path.replace(/r\/(.*)\/produits\/(.*)-(.*)\.htm/,'$1/fr/product/$2_$3'))
                return { path: '/fr'}
                return { path: `/r/${path.replace(/r\/(.*)\/produits\/(.*)-(.*)\.htm/,'$1/fr/product/$2_$3')}`, query: to.query };
              }
                */
            }
            

            // Check the first part of the path for a language code
            const langMatch = to.fullPath.match(/^\/([a-z]{2})(\/|$)/);
            const lang = langMatch && langMatch[1];
            
            // If the language isn't valid or missing, redirect to 'fr'
            if (!supportedLanguages.includes(lang)) {
              // we check if the path exist in the routes
              return { path: `/fr${to.fullPath}`, query: to.query };
            }
    
            return to.fullPath;
          }
        }
    ],
    scrollBehavior(to, from, savedPosition) {
      // always scroll to top
      if (to.hash) {
        return {
          el: to.hash,
          behavior: 'smooth'
        }
      }
      return { top: 0, behavior: 'smooth' }
    }


})



// Global Navigation Guard to handle default language
router.beforeEach(async (to, from) => {
    // we close the menu
    const settingsStore = useSettingsStore();
    settingsStore.menuOpen = false;
    // we check if the route is a referal route
    if(to.meta.referal){
      // we save the referal in local storage
      const refer = to.params.refer;
      if(refer){
        const storage = useStorage();
        storage.setStorageSync('referer',refer,  3600*24*15*1000);
        saveReferral(refer).then((response) => {
          console.log('saveReferral',response)
        }
        ).catch((error) => {
          console.log('saveReferral error',error)
        });

      
      }
    }
    // we first check if we have a meta with the lang defined
    let lang= null;
    if(to.meta.lang){
      lang = to.meta.lang;
    }
    else{
      // Extract the language from the URL path
      const langMatch = to.path.match(/^\/([a-z]{2})(\/|$)/);
      lang = langMatch && langMatch[1];
    }

    // Redirect to the default language if no valid language prefix is present
    if (!lang || !supportedLanguages.includes(lang)) {
        return { path: `/fr${to.fullPath}`, replace: true, query: to.query };
    }
    
    // Update the i18n locale if it has changed
    if (i18n.global.locale.value !== lang) {
        i18n.global.locale.value = lang;
    }
    // we check is route need auth
    if(to.meta.middleware){
      if(to.meta.middleware.includes('auth')){
        const authStore = useAuthStore()
        if(authStore.isExpired() || !authStore.isLogged()){
          // if we have meta auth_redirect we redirect to this route else we redirect to login
          if(to.meta.auth_redirect){
            return { name: `${lang}.${to.meta.auth_redirect}`, query: { redirect: to.fullPath } };
          }
          return { name: `${lang}.account.login`, query: { redirect: to.fullPath } };
        }
      }

    }
    
});



export default router
